//
// Component: Hand
//
// Displays and controls a player's hand
//

// dependencies
import React from 'react'
import { Box, Flex } from 'rebass'

import GameStore from 'App/stores/GameStore'

import Card from 'App/components/card/Card.js'

class Hand extends React.Component {
  //
  // props
  //
  // active - north, south, east, west - the hand that is in play
  // displayAsFan - true/false - display hand in fan style
  // dummy - true/false - your partner
  // hand - cards in PBN format
  // horizontalCards - true/false - orientation of cards
  // horizontalHand - true/false - orientation of hand
  // player - north, south, east, west - this player
  // showMarker - true/false - not sure what this is yet
  // visible - true/false - if false the back of the card will be shown
  // spacing - passed through to Card to define spacing between cards
  //           this will be different for a full hand and just one suit

  constructor (props) {
    super(props)
    this.state = {
    }

    this.cardClicked = this.cardClicked.bind(this) // called after rendered so need to bind it to this component
  }

  componentDidMount () {

  }

  cardClicked (hand, rank, suit) {
    // Card has been clicked & this has been passed up from Card
    // Therefore anything in here refers to the Card not Hand
    // so this will not work for Hand functions
    //
    // However, bind it in the constructor and it works

    this.props.cardClicked(hand, rank, suit)
  }

  //
  // FUNCTION: renderHand
  //
  // Builds a Hand of Card components
  // Note that this function has been partially copied
  // from the BridgeJS library
  //
  // INPUT: pbn - a hand in PBN notation
  //

  renderHand (pbn) {
    var suit = 'S'
    var rank
    var i
    var hand = []

    // pbnOrder - defines what the next suit is
    // eg. if Spades, next suit will be Hearts
    var pbnOrder = {
      S: 'H',
      H: 'D',
      D: 'C',
      C: null
    }
    var cardCounter = 1

    if (pbn) {
      for (i = 0; i < pbn.length; ++i) {
        rank = pbn[i]
        if (rank === '.') {
          suit = pbnOrder[suit]
        } else {
          hand.push(<Card spacing={this.props.spacing} id={this.props.player + cardCounter} hand={this.props.player} suit={suit} rank={rank} visible={((GameStore.turnNumber > 1 || GameStore.trickNumber > 1) && this.props.dummy) ? true : this.props.visible} horizontalCard={this.props.horizontalCards} horizontalHand={this.props.horizontalHand} cardClicked={this.cardClicked} />)
          cardCounter++
        }
      }

      return hand
    }
  }

  render () {
    var hand = this.renderHand(this.props.hand)

    const handView =
      <Box id='hand' className={'hand player-' + this.props.player + ' ' + (this.props.active === this.props.player ? 'active-hand ' : '') + ' ' + (this.props.horizontalHand ? 'hhand-compact ' : 'vhand-compact ')}>
        {hand}
      </Box>

    // Arrange the hand of cards and player name correctly
    var marginTop
    var marginBottom
    var marginRight
    var marginLeft
    switch (this.props.player) {
      case 'north':
        marginBottom = [0, 100]
        break
      case 'east':
        marginLeft = [0, 60]
        break
      case 'south':
        marginTop = [0, 100]
        break
      case 'west':
        marginRight = [0, 60]
        break
    }

    const handContainer =
      <Flex
        sx={{
          marginTop: marginTop,
          marginBottom: marginBottom,
          marginRight: marginRight,
          marginLeft: marginLeft
        }}
        className={'player-hand ' + (this.props.horizontalHand ? 'horizontal-hand' : 'vertical-hand')}
      >
        {handView}
      </Flex>

    return (
      <Flex className='player-hand-container'>
        {handContainer}
      </Flex>
    )
  }
}
export default Hand
