// dependencies
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Box } from 'rebass'
import { library } from '@fortawesome/fontawesome-svg-core'

import { faSpade, faHeart, faClub, faDiamond, faQuestionSquare, faBars, faTimes } from '@fortawesome/pro-solid-svg-icons'

// assets
import 'App/assets/css/App.css'

// screens
import Game from 'App/screens/Game.js'

export default function App () {
  // setup react
  library.add(faSpade, faHeart, faClub, faDiamond, faQuestionSquare, faBars, faTimes)

  return (
    <Router>
      <Box
        sx={{
          padding: [0, 50],
          maxWidth: 800,
          margin: '0 auto'
        }}
      >
        <Game />
      </Box>
    </Router>
  )
}
