// dependencies
import React from 'react'
import { Heading } from 'rebass'

// components
import Translate from 'App/components/text/Translate.js'

export default function (props) {
  return (
    <Heading as='h1' {...props} fontSize={20}>
      <Translate>{props.children}</Translate>
    </Heading>
  )
}
