//
// Component: TableBids
//
// Displays the players bids on the table
//

// dependencies
import React from 'react'
import { Box } from 'rebass'

// stores
// import GameStore from 'App/stores/GameStore'

// components
import BidCard from 'App/components/auction/BidCard.js'

export default function (props) {
  return (
    <Box>
      <Box
        sx={{
          position: 'absolute',
          top: [0, 0],
          left: '50%',
          ml: [-20, -25]
        }}
      >
        <BidCard bidValue='3' bidSuit='club' />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          mt: [-20, -25]
        }}
      >
        <BidCard pass='true' />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          ml: [-20, -25]
        }}
      >
        <BidCard bidValue='1' bidSuit='spade' />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          mt: [-20, -25]
        }}
      >
        <BidCard bidValue='2' bidSuit='heart' />
      </Box>
    </Box>
  )
}
