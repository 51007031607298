/**
 * Component: MenuButton
 *
 * Displays and controls the menu button
 */

import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'rebass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class MenuButton extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      menuDisplayed: false
    }

    this.showMenu = this.showMenu.bind(this)
  }

  showMenu () {
    this.props.showMenu()
  }

  render () {
    return (
      <div>
        <Button
          sx={{
            backgroundColor: 'transparent',
            fontSize: [3, 5],
            outline: 'transparent', // overrides an outline on :focus from rebass
            cursor: 'pointer'
          }}
          // eslint-disable-next-line react/jsx-handler-names
          onClick={this.showMenu}
        >
          {
            this.props.menuDisplayed
              ? (
                <FontAwesomeIcon icon={['fas', 'times']} />
              )
              : (
                <FontAwesomeIcon icon={['fas', 'bars']} />
              )
          }
        </Button>
      </div>
    )
  }
}

MenuButton.propTypes = {
  /** Shows if the menu is displayed */
  menuDisplayed: PropTypes.bool
}
export default MenuButton
