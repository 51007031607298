//
// Component: Card
//
// Displays and controls a player's cards
//

// dependencies
import React from 'react'
import { Image } from 'rebass'

// Stylesheet
import './cards.css'

// components
import P from 'App/components/text/P.js'

// stores
// import GameStore from 'App/stores/GameStore'

class Card extends React.Component {
  //
  // props
  //
  // hand - north, south, east, west
  // horizontalCard - true/false - orientation of card
  // horizontalHand - true/false - orientation of hand the card belongs to
  // id - card identifier eg. north1, north2 etc
  // rank - A, 2, 3 ... J, Q, K
  // suit - S, H, D, C
  // visible - true/false - if false the back of the card will be shown
  //

  constructor (props) {
    super(props)
    this.state = {
    }
    this.handleCardClicked = this.cardClicked.bind(this)
  }

  componentDidMount () {

  }

  cardClicked () {
    this.props.cardClicked(this.props.hand, this.props.rank, this.props.suit)
  }

  render () {
    var card = <P />
    var marginTop
    var marginLeft

    // Set default card widths for mobile and desktop views
    var mobileWidth = 50
    var desktopWidth = 70

    // Card height multiplier
    var cardHeightMultiplier = 1.4

    var mobileHeight = Math.floor(mobileWidth * cardHeightMultiplier)
    var desktopHeight = Math.floor(desktopWidth * cardHeightMultiplier)

    const spacing = this.props.spacing

    // Set up spacing between cards depending on whether the Hand is horizontal or vertical
    var mobileMarginLeft = -mobileWidth * (1.0 - spacing)
    var desktopMarginLeft = -desktopWidth * (1.0 - spacing)

    var mobileMarginTop = -mobileHeight * (1.0 - spacing)
    var desktopMarginTop = -desktopHeight * (1.0 - spacing)

    if (this.props.horizontalHand) {
      marginTop = [0, 0]
      marginLeft = [mobileMarginLeft, desktopMarginLeft]
    } else {
      marginTop = [mobileMarginTop, desktopMarginTop]
      marginLeft = [0, 0]
    }

    if (!this.props.visible) {
      card = (
        <Image
          sx={{
            width: [mobileWidth, desktopWidth],
            height: [mobileHeight, desktopHeight],
            marginTop: marginTop,
            marginLeft: marginLeft
          }}
          className={'card ' + (this.props.horizontalCard ? 'hcard' : 'vcard')} src={require('./images/2B.svg')}
        />
      )
    } else if (this.props.rank && this.props.suit) {
      card = (
        <Image
          sx={{
            width: [mobileWidth, desktopWidth],
            height: [mobileHeight, desktopHeight],
            marginTop: marginTop,
            marginLeft: marginLeft
          }}
          id={this.props.id} className={'card ' + (this.props.hand || '') + ' ' + (this.props.table === 'true' ? 'table' : '') + ' ' + (this.props.horizontalCard ? 'hcard' : 'vcard')} src={require('./images/' + this.props.rank + this.props.suit + '.svg')} onClick={this.handleCardClicked}
        />
      )
    }

    return card
  }
}
export default Card
