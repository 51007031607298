//
// Component: TableCards
//
// Displays the players cards played on the table
//

// dependencies
import React from 'react'
import { Flex, Box } from 'rebass'
import { observer } from 'mobx-react'

// stores
import GameStore from 'App/stores/GameStore'

// components
import Card from 'App/components/card/Card.js'

// @observer
const TableCards = observer(class TableCards extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {

  }

  render () {
    return (
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: [180, 260],
          width: [180, 260]
        }}
      >
        <Box>
          <Card suit={GameStore.table.north.suit} rank={GameStore.table.north.rank} visible='true' table='true' hand='north' />
          <Card suit={GameStore.table.west.suit} rank={GameStore.table.west.rank} visible='true' table='true' hand='west' />
          <Card suit={GameStore.table.east.suit} rank={GameStore.table.east.rank} visible='true' table='true' hand='east' />
          <Card suit={GameStore.table.south.suit} rank={GameStore.table.south.rank} visible='true' table='true' hand='south' />
        </Box>
      </Flex>
    )
  }
})
export default TableCards
