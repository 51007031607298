//
// Component: PlayerName
//
// Displays the player name ie. north, south, east, west
//

// dependencies
import React from 'react'
import { Flex } from 'rebass'

// components
import Label from 'App/components/text/Label.js'

export default function (props) {
  if (props.active === props.children.toLowerCase()) {
    var borderWidth = '1px'
    var borderStyle = 'solid'
    var borderColor = '#fff'
  }
  return (
    <Flex
      {...props}
      sx={{
        bg: '#9d2723',
        justifyContent: 'center',
        py: 1,
        width: [90, 120],
        height: [20, 26],
        margin: '0 auto',
        textTransform: 'uppercase',
        borderWidth: borderWidth,
        borderStyle: borderStyle,
        borderColor: borderColor
      }}
    >
      <Label
        sx={{
          fontSize: [0, 2],
          alignSelf: 'center'
        }}
      >
        {props.children}
      </Label>
    </Flex>
  )
}
