//
// Component: MenuScenario
//
// Displays & controls the scenario selection menu
//

// dependencies
import React from 'react'

import { Box } from 'rebass'

// components
import H1 from 'App/components/text/H1.js'
import Li from 'App/components/text/Li.js'

// stores
// import GameStore from 'App/stores/GameStore'

class MenuScenario extends React.Component {
  //
  // props
  //
  // None at the moment but expect them to be fed through from GameStore
  //

  constructor (props) {
    super(props)
    this.state = {
    }
    // this.scenarioSelected = this.scenarioSelected.bind(this)
  }

  componentDidMount () {

  }

  scenarioSelected (scenario) {
    this.props.scenarioSelected(scenario)
  }

  render () {
    return (
      <Box>
        <H1>Select a Scenario</H1>
        <ul>
          {/* eslint-disable-next-line react/jsx-handler-names */}
          <Li onClick={() => { this.scenarioSelected(1) }}>Scenario 1</Li>
          <Li onClick={() => { this.scenarioSelected(2) }}>Scenario 2</Li>
        </ul>
      </Box>
    )
  }
}
export default MenuScenario
