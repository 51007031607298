//
// Component: Game
//
// Displays and controls the game board
//

// dependencies
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { get } from 'mobx'
import { observer } from 'mobx-react'
import { Box, Flex } from 'rebass'

// services
// import Api from 'App/services/Api.js'

// stores
import GameStore from '../stores/GameStore'
// import AuthStore from 'App/stores/AuthStore.js'

// components
import Hand from 'App/components/hand/Hand.js'
import NavBar from 'App/components/navbar/NavBar'
import PlayerName from 'App/components/playerName/PlayerName.js'
import TableCards from 'App/components/table/TableCards.js'
import TableBids from 'App/components/table/TableBids.js'
import MessageModal from 'App/components/modal/MessageModal.js'
import PlayerBid from 'App/components/auction/PlayerBid.js'
import MyBid from 'App/components/auction/MyBid.js'
import MenuScenario from 'App/components/menu/MenuScenario.js'

@observer
class Game extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      markerVisible: {
        north: false,
        south: false,
        east: false,
        west: false
      },
      handVisible: {
        north: false,
        south: false,
        east: false,
        west: false
      },
      displayAsFan: {
        north: false,
        south: false,
        east: false,
        west: false
      },
      horizontalHand: {
        north: false,
        south: false,
        east: false,
        west: false
      },
      horizontalCards: {
        north: false,
        south: false,
        east: false,
        west: false
      },
      modalIsOpen: false,
      modalText: '',
      modalCloseWithOverlay: true,
      bidPlayer: null,
      bidRound: 1
    }
    this.cardClicked = this.cardClicked.bind(this) // called after rendered so need to bind it to this component
    this.showModal = this.showModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.menuClicked = this.menuClicked.bind(this)
    this.dealHands = this.dealHands.bind(this)
    this.doNextBid = this.doNextBid.bind(this)
  }

  // Some useful 'global' lookup variables
  suitLookup = {
    S: 'spade',
    H: 'heart',
    D: 'diamond',
    C: 'club'
  }

  seatOrder = {
    S: 0,
    W: 1,
    N: 2,
    E: 3
  }

  componentDidMount () {

  }

  closeModal () {
    this.setState({
      modalIsOpen: false
    })
  }

  showModal () {
    this.setState({
      modalIsOpen: true
    })
  }

  // toggleModal () {
  //   this.setState({
  //     modalIsOpen: !this.state.modalIsOpen
  //   })
  // }

  cardClicked (hand, rank, suit) {
    // Card has been clicked & this has been passed up from Card
    // Therefore anything in here refers to the Card not Hand
    // so this will not work for Hand functions
    //
    // However, bind it in the constructor and it works

    var playCardResult = GameStore.playCard(hand, rank, suit)

    if (playCardResult) {
      this.setState({
        modalText: playCardResult
      })
      this.showModal()
    }
  }

  dealHands (scenario) {
    // Deal hands for selected scenario
    this.closeModal()
    GameStore.dealHands(scenario)
    this.setMarker()
    this.setHandVisibility()
    // Now start the bidding
    this.auction()
  }

  auction () {
    this.doNextBid()
  }

  //
  // FUNCTION: doNextBid
  //
  // Handles the next player bid
  // Initially the player bid state is set to null
  // which triggers W to bid
  // Bidding continues for N & E
  // before a special bid by S, the human player
  //

  doNextBid () {
    var suitName = {
      S: 'spade',
      H: 'heart',
      D: 'diamond',
      C: 'club'
    }

    var playerName = {
      W: 'west',
      N: 'north',
      E: 'east',
      S: 'south'
    }

    var playerOrder = {
      W: 'N',
      N: 'E',
      E: 'S'
    }

    var player = this.state.bidPlayer ? this.state.bidPlayer : 'W'

    var bidRound = this.state.bidRound
    var bid = GameStore.getBid(player, bidRound)

    if (bid === null) {
      this.closeModal()
      return
    }

    if (player === 'S') {
      this.setState({
        modalCloseWithOverlay: false,
        modalText: <MyBid player={playerName[player]} bid={bid.options} validOption={bid.validOption} doNextBid={this.doNextBid} />,
        bidRound: ++this.state.bidRound
      })
    } else {
      this.setState({
        modalCloseWithOverlay: false,
        modalText: <PlayerBid player={playerName[player]} bidValue={bid.value} bidSuit={suitName[bid.suit]} pass={bid.pass} doNextBid={this.doNextBid}>{bid.reason}</PlayerBid>
      })
    }

    this.showModal()

    this.setState({
      bidPlayer: playerOrder[player]
    })
  }

  // leftOf (str, chr) {
  //   return str.split(chr)[0]
  // }

  // rightOf (str, chr) {
  //   return str.split(chr)[1]
  // }

  setMarker () {
    var markerVisible = {
      north: false,
      south: false,
      east: false,
      west: false
    }

    markerVisible[GameStore.turn] = true

    this.setState({ markerVisible: markerVisible })
  }

  setHandVisibility () {
    var handVisible = {
      north: GameStore.systemOverride,
      south: GameStore.systemOverride,
      east: GameStore.systemOverride,
      west: GameStore.systemOverride
    }

    handVisible.south = true

    // Is the card hand in fan shape?
    var displayAsFan = {
      north: false,
      south: true,
      east: false,
      west: false
    }

    // Is the hand vertical or horizontal?
    var horizontalHand = {
      north: true,
      south: true,
      east: false,
      west: false
    }

    // Are the cards vertical or horizontal?
    var horizontalCards = {
      north: false,
      south: false,
      east: true,
      west: true
    }

    this.setState({ handVisible: handVisible })
    this.setState({ displayAsFan: displayAsFan })
    this.setState({ horizontalHand: horizontalHand })
    this.setState({ horizontalCards: horizontalCards })
  }

  menuClicked (menu) {
    // NOTE: This modalText needs sanitizing somehow - use dangerouslySetInnerHTML ?

    switch (menu) {
      case 1:
        this.setState({
          modalText: <MenuScenario scenarioSelected={this.dealHands} />
        })
        this.showModal()
        break
      case 2:
        var trickHistoryDisplay
        var trickHistory = GameStore.getTrickHistory()

        if (Array.isArray(trickHistory)) {
          var roundArray = []
          var trickArray = []

          // Build Table of tricks
          roundArray = trickHistory.map(function (trick) {
            // Create dummy array so that if starting
            // player is not south the cards go in the right place
            trickArray = [
              <td key={trickArray.id} />,
              <td key={trickArray.id} />,
              <td key={trickArray.id} />,
              <td key={trickArray.id} />
            ]
            var round = trick.play
            for (var i = 0; i < round.length; i++) {
              trickArray[this.seatOrder[round[i].seat.symbol]] = <td key={round[i].id} className={`${this.suitLookup[round[i].card.suit]} ${i === 0 ? 'start-player' : null}`}>{round[i].card.rank}<FontAwesomeIcon icon={['fas', this.suitLookup[round[i].card.suit]]} /></td>
            }
            return <tr key={trick.id}>{trickArray}</tr>
          }, this) // Need this here so it references correct this inside map function
          trickHistoryDisplay = <table className='trick-history'><thead><tr><th>S</th><th>W</th><th>N</th><th>E</th></tr></thead><tbody>{roundArray}</tbody></table>
        } else {
          trickHistoryDisplay = trickHistory
        }

        this.setState({
          modalCloseWithOverlay: true,
          modalText: trickHistoryDisplay,
          showTextModal: true
        })
        this.showModal()
        break
    }
  }

  render () {
    return (
      <>
        <NavBar menuClicked={this.menuClicked} />
        <Flex
          sx={{
            p: 2,
            color: 'white',
            flexShrink: 0,
            justifyContent: 'center',
            alignItems: 'center',
            width: [375, 600, 700],
            height: [400, 600, 700],
            margin: '30px auto',
            overflow: 'hidden'
          }}
        >
          {/* Main table view */}
          <Box
            sx={{
              backgroundImage: 'url(shutterstock_347563919.jpg)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              borderRadius: 16,
              borderTop: 4,
              borderRight: 4,
              borderBottom: 4,
              borderLeft: 4,
              borderColor: '#492a2c',
              borderStyle: 'solid',
              width: [300, 500],
              height: [300, 500],
              position: 'relative'
            }}
          >
            <MessageModal showModal={this.state.modalIsOpen} closeModal={this.closeModal} closeWithOverlay={this.state.modalCloseWithOverlay}>
              {this.state.modalText}
            </MessageModal>

            {/* Outer view 1 */}
            {/* This holds the Player names and the inner view */}
            <Box
              sx={{
                width: [240, 340],
                height: [240, 340],
                borderWidth: 2,
                borderColor: 'red',
                // borderStyle: 'solid',
                position: 'absolute',
                left: '50%',
                ml: [-120, -170],
                top: '50%',
                mt: [-120, -170]
              }}
            >
              <Flex
                sx={{
                  justifyContent: 'center',
                  flexDirection: 'column',
                  borderWidth: 2,
                  borderColor: 'blue',
                  // borderStyle: 'solid',
                  height: 120,
                  width: '100%',
                  position: 'absolute',
                  top: [-90]
                }}
              >
                {GameStore.singleSuitHands.north.length > 0
                  ? (
                    <Hand spacing='1.2' player='north' dummy={GameStore.dummy === 'north'} active={GameStore.turn} hand={GameStore.singleSuitHands.north} visible={this.state.handVisible.north} showMarker={this.state.markerVisible.north} displayAsFan={this.state.displayAsFan.north} horizontalHand={this.state.horizontalHand.north} horizontalCards={this.state.horizontalCards.north} cardClicked={this.cardClicked} />
                  ) : (
                    <Hand spacing='0.3' player='north' dummy={GameStore.dummy === 'north'} active={GameStore.turn} hand={GameStore.hands.north} visible={this.state.handVisible.north} showMarker={this.state.markerVisible.north} displayAsFan={this.state.displayAsFan.north} horizontalHand={this.state.horizontalHand.north} horizontalCards={this.state.horizontalCards.north} cardClicked={this.cardClicked} />
                  )}
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: '50%',
                    marginLeft: [-45, -60]
                  }}
                >
                  <PlayerName active={GameStore.turn}>North</PlayerName>
                </Box>
              </Flex>
              <Flex
                sx={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 2,
                  borderColor: 'red',
                  // borderStyle: 'solid',
                  width: [120, 180],
                  height: '100%',
                  position: 'absolute',
                  left: [210, 305]
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: [-35, -45],
                    // right: [20, 26], /* 26 is the height of the PlayerName container (20 for mobile) */
                    mt: [-13, -13],
                    transform: 'rotate(-90deg)'
                  }}
                >
                  <PlayerName active={GameStore.turn}>East</PlayerName>
                </Box>
                <Hand spacing='0.2' player='east' dummy={GameStore.dummy === 'east'} active={GameStore.turn} hand={GameStore.hands.east} visible={this.state.handVisible.east} showMarker={this.state.markerVisible.east} displayAsFan={this.state.displayAsFan.east} horizontalHand={this.state.horizontalHand.east} horizontalCards={this.state.horizontalCards.east} cardClicked={this.cardClicked} />
              </Flex>
              <Flex
                sx={{
                  justifyContent: 'center',
                  flexDirection: 'column',
                  borderWidth: 2,
                  borderColor: 'green',
                  // borderStyle: 'solid',
                  height: 120,
                  width: '100%',
                  position: 'absolute',
                  top: [210, 305]
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    marginLeft: [-45, -60]
                  }}
                >
                  <PlayerName active={GameStore.turn}>South</PlayerName>
                </Box>
                {GameStore.singleSuitHands.south.length > 0
                  ? (
                    <Hand spacing='1.2' player='south' dummy={GameStore.dummy === 'south'} active={GameStore.turn} hand={GameStore.singleSuitHands.south} visible={this.state.handVisible.south} showMarker={this.state.markerVisible.south} displayAsFan={this.state.displayAsFan.south} horizontalHand={this.state.horizontalHand.south} horizontalCards={this.state.horizontalCards.south} cardClicked={this.cardClicked} />
                  ) : (
                    <Hand spacing='0.3' player='south' dummy={GameStore.dummy === 'south'} active={GameStore.turn} hand={GameStore.hands.south} visible={this.state.handVisible.south} showMarker={this.state.markerVisible.south} displayAsFan={this.state.displayAsFan.south} horizontalHand={this.state.horizontalHand.south} horizontalCards={this.state.horizontalCards.south} cardClicked={this.cardClicked} />
                  )}
              </Flex>
              <Flex
                sx={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 2,
                  borderColor: 'yellow',
                  // borderStyle: 'solid',
                  width: [120, 180],
                  height: '100%',
                  position: 'absolute',
                  left: [-90, -140]
                }}
              >
                <Hand spacing='0.2' player='west' dummy={GameStore.dummy === 'west'} active={GameStore.turn} hand={GameStore.hands.west} visible={this.state.handVisible.west} showMarker={this.state.markerVisible.west} displayAsFan={this.state.displayAsFan.west} horizontalHand={this.state.horizontalHand.west} horizontalCards={this.state.horizontalCards.west} cardClicked={this.cardClicked} />
                <Box
                  sx={{
                    position: 'absolute',
                    left: [65, 100],
                    top: '50%',
                    mt: [-13, -13],
                    transform: 'rotate(90deg)'
                  }}
                >
                  <PlayerName active={GameStore.turn}>West</PlayerName>
                </Box>
              </Flex>
            </Box>

            {/* Inner view */}
            {/* This will display the Bid Cards or played cards */}
            <Box
              sx={{
                width: [180, 260],
                height: [180, 260],
                borderWidth: 2,
                borderColor: 'pink',
                // borderStyle: 'solid',
                position: 'absolute',
                left: '50%',
                ml: [-90, -130],
                top: '50%',
                mt: [-90, -130]
              }}
            >
              {GameStore.gameOver ? <Box>*** GAME OVER ***</Box> : ''}
              {GameStore.phase === 'play' ? <TableCards /> : ''}
              {GameStore.phase === 'auction' ? <TableBids /> : ''}
            </Box>
          </Box>
        </Flex>
      </>
    )
  }
}
export default Game
