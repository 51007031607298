//
// Component: PlayerBid
//
// Displays the bid made by the
// other three players
//

// dependencies
import React from 'react'
import { Box, Button, Flex } from 'rebass'

// components
import BidCard from 'App/components/auction/BidCard.js'
// import H1 from 'App/components/text/H1.js'
import Text from 'App/components/text/Text.js'

// stores
// import GameStore from 'App/stores/GameStore'

class PlayerBid extends React.Component {
  //
  // props
  //
  // player - north, south, east, west
  // bidValue - 1-7
  // bidSuit - S, H, D, C
  // pass - true - omit bidValue and bidSuit if true
  // comment - text to display
  //

  constructor (props) {
    super(props)
    this.state = {
    }
    this.doNextBid = this.doNextBid.bind(this)
  }

  componentDidMount () {

  }

  doNextBid () {
    this.props.doNextBid()
  }

  render () {
    return (
      <Flex
        sx={{
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            textTransform: 'uppercase',
            margin: '0 auto',
            mb: [20]
          }}
        >
          {this.props.player}'s Bid
        </Box>
        <BidCard bidValue={this.props.bidValue} bidSuit={this.props.bidSuit} pass={this.props.pass} alignSelf='center' />
        <Text
          sx={{
            fontWeight: 700,
            mt: [20],
            '&:first-letter': {
              textTransform: 'uppercase'
            }
          }}
        >
          {this.props.player} bids {this.props.pass ? 'pass' : `${this.props.bidValue} ${this.props.bidSuit}s`}.
        </Text>
        <Text
          sx={{
            fontWeight: 300,
            mb: [20]
          }}
        >
          {this.props.children}
        </Text>
        <Button
          sx={{
            textTransform: 'uppercase',
            backgroundColor: '#46282C',
            borderRadius: 0
          }}
          // eslint-disable-next-line react/jsx-handler-names
          onClick={this.doNextBid}
        >
            Continue
        </Button>
      </Flex>
    )
  }
}
export default PlayerBid
