//
// BidCard
//
// Displays a bid like a Bridge bid card
//
// dependencies
import React from 'react'
import { Flex } from 'rebass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// components
import Text from 'App/components/text/Text.js'

export default function (props) {
  var bidValue = props.bidValue
  var bidSuit = props.bidSuit
  var pass = props.pass
  var bg = pass === true ? '#41a437' : '#fff'
  var color = pass === true ? '#fff' : (bidSuit === 'H' || bidSuit === 'D') ? '#df343a' : '#000'
  var cardText

  // var suitName = {
  //   S: 'spade',
  //   H: 'heart',
  //   D: 'diamond',
  //   C: 'club'
  // }

  if (pass === true) {
    cardText =
      <Text
        sx={{
          fontSize: [1, 2],
          fontWeight: 500
        }}
      >
            pass
      </Text>
  } else {
    cardText =
      <Text
        sx={{
          fontSize: [2, 4],
          fontWeight: 700
        }}
      >
        {bidValue}<FontAwesomeIcon icon={['fas', bidSuit]} className={`color-${bidSuit}`} />
      </Text>
  }

  return (
    <Flex
      {...props}
      sx={{
        bg: bg,
        color: color,
        justifyContent: 'center',
        alignItems: 'center',
        width: [40, 50],
        height: [40, 50],
        borderRadius: 8,
        borderStyle: pass === true ? 'none' : 'solid',
        borderColor: '#000'
      }}
    >
      {cardText}
    </Flex>
  )
}
