//
// Store: GameStore
//
// The guts of the app. Controls everything.
//

import { observable, autorun } from 'mobx'

import React from 'react'
// import { mobxDidRunLazyInitializersSymbol } from 'mobx/lib/internal'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// @observer
class GameStore extends React.Component {
  // constructor (props) {
  // super(props)
  // }

  checkForBotsTurn = autorun(() => {
    if (this.turn === 'east' || this.turn === 'west') {
      this.botPlay(this.turn)
    }
  })

  // systemOverride set to true will
  // set all hands to visible
  @observable systemOverride = false

  @observable turn // north, south, east, west, system
  @observable turnNumber = 1
  @observable trickNumber = 1

  @observable phase = 'play' // boxed, loaded, scenario, setup, bid, play, review
  @observable declarer = 'south'
  @observable dummy = 'north'
  @observable leadCardSuit = ''

  @observable scenario = null
  @observable bid = {}

  @observable board = {}

  @observable hands = {
    north: {},
    east: {},
    south: {},
    west: {}
  }

  @observable singleSuitHands = { // Player has clicked on hand and only cards of that suit are displayed
    north: '',
    east: '',
    south: '',
    west: ''
  }

  @observable cards = {
    north: [],
    east: [],
    south: [],
    west: []
  }

  // Cards played to the Table
  @observable table = {
    north: {
      rank: '',
      suit: ''
    },
    east: {
      rank: '',
      suit: ''
    },
    south: {
      rank: '',
      suit: ''
    },
    west: {
      rank: '',
      suit: ''
    }
  }

  // Array of players that won each trick
  @observable tricksWon = []

  // Counts of tricks won by partners
  @observable tricksWonByPartners = {
    northsouth: 0,
    eastwest: 0
  }

  @observable score = {}
  @observable previousScore = {}

  // Set to true after 13 tricks played
  @observable gameOver = false

  // Scenario's that will eventually be accessed by API
  //
  // In PBN notation
  //
  // N indicates start deal player (I think)
  //
  // Dot separates suits, in the order SHDC
  // Space separates players hands
  @observable scenario = [
    // 'N:A.QJ.. Q..K4. .A.9.J ..J6.9',
    'N:A843.QJ6.K.A6542 KQ9752.K74.8742. T.A93.QT93.KJ873 J6.T852.AJ65.QT9',
    'N:K843.QJ6.K.A6542 AQ9752.K74.8742. J.A93.QT93.KJ873 T6.T852.AJ65.QT9'
  ]

  // Bid's that will eventually be accessed by API
  //
  // Bid starts with W
  //
  // W, N & E bids shown with reason
  //
  // 4 options then given for player (South) to choose from
  // with a number to indicate which of the four options is the correct one

  @observable bids = [
    {
      W: {
        value: null,
        suit: null,
        pass: true,
        reason: 'Reason 1'
      },
      N: {
        value: 1,
        suit: 'H',
        pass: false,
        reason: 'Reason 2'
      },
      E: {
        value: 1,
        suit: 'S',
        pass: false,
        reason: 'Reason 3'
      },
      S: {
        options: [
          {
            value: 2,
            suit: 'S',
            pass: false,
            reason: 'Not Correct 1'
          },
          {
            value: 1,
            suit: 'D',
            pass: false,
            reason: 'Not Correct 2'
          },
          {
            value: 3,
            suit: 'S',
            pass: false,
            reason: 'Correct'
          },
          {
            value: null,
            suit: null,
            pass: true,
            reason: 'Not Correct 3'
          }
        ],
        validOption: 3
      }
    },
    {
      W: {
        value: 1,
        suit: 'S',
        pass: false,
        reason: 'Reason 1'
      },
      N: {
        value: 2,
        suit: 'S',
        pass: false,
        reason: 'Reason 2'
      },
      E: {
        value: null,
        suit: null,
        pass: true,
        reason: 'Reason 3'
      },
      S: {
        options: [
          {
            value: 1,
            suit: 'S',
            pass: false,
            reason: 'Correct'
          },
          {
            value: null,
            suit: null,
            pass: true,
            reason: 'Not Correct 1'
          },
          {
            value: 2,
            suit: 'H',
            pass: false,
            reason: 'Not Correct 2'
          },
          {
            value: 1,
            suit: 'S',
            pass: false,
            reason: 'Not Correct 3'
          }
        ],
        validOption: 1
      }
    }
  ]

  // Some useful 'global' lookup variables
  suitOrder = {
    S: 0,
    H: 1,
    D: 2,
    C: 3
  }

  seatOrder = {
    S: 0,
    W: 1,
    N: 2,
    E: 3
  }

  // setup = function () {
  //   // setup the table

  //   // shuffle the deck
  //   // var deck = new global.bridge.Deck().shuffle()

  //   // deal the cards
  //   // var hands = deck.deal(global.bridge.seat.west)

  //   var board = new global.bridge.Board()
  //   board.setNumber(1)
  //   board.deal('N:A843.QJ6.K.A6542 KQ9752.K74.8742. T.A93.QT93.KJ873 J6.T852.AJ65.QT9')
  //   this.board = board

  //   // this.cards.north = hands.north.cards
  //   // this.cards.east = hands.east.cards
  //   // this.cards.south = hands.south.cards
  //   // this.cards.west = hands.west.cards
  //   // this.handN = board.hands[global.bridge.seat.north].toPBN()
  //   // this.handS = board.hands[global.bridge.seat.south].toPBN()

  //   this.phase = 'setup'
  // }

  //
  // FUNCTION: initialise
  //
  // Initialises gameStore variables

  initialise = function () {
    this.winner = null
    this.tricksWonByPartners.northsouth = 0
    this.tricksWonByPartners.eastwest = 0
    this.gameOver = false

    this.table = {
      north: {
        rank: '',
        suit: ''
      },
      east: {
        rank: '',
        suit: ''
      },
      south: {
        rank: '',
        suit: ''
      },
      west: {
        rank: '',
        suit: ''
      }
    }

    this.singleSuitHands = {
      north: '',
      east: '',
      south: '',
      west: ''
    }
  }

  //
  // FUNCTION: dealHands
  //
  // Sets up the Board based on the given scenario ie. deals four hands
  // Sets up the Game
  //
  // INPUT: scenario - unique number to reference a given scenario
  //

  dealHands = function (scenario) {
    this.initialise()

    var board = new global.bridge.Board()
    board.setNumber(scenario) // Do we need this ? What does it do ?
    board.deal(this.scenario[scenario - 1])
    this.board = board

    this.phase = 'deal'

    // PBN - Portable Bridge Notation
    // We use this notation to define the hands
    //
    // eg. 'N:A843.QJ6.K.A6542 KQ9752.K74.8742. T.A93.QT93.KJ873 J6.T852.AJ65.QT9'
    //
    // - Deal from North
    // - Cards in order SHDC separated by full stops
    // - Hands in order NESW separated by space
    this.hands.north = board.hands[global.bridge.seat.north].toPBN()
    this.hands.south = board.hands[global.bridge.seat.south].toPBN()
    this.hands.east = board.hands[global.bridge.seat.east].toPBN()
    this.hands.west = board.hands[global.bridge.seat.west].toPBN()

    var game = new global.bridge.Game()
    // Contract and declaror assumed for now
    game.contract.level = 3
    this.bid.level = game.contract.level

    game.contract.denomination = 'NT'
    this.bid.denomination = game.contract.denomination

    game.contract.declaror = global.bridge.seat.east

    this.declarer = game.contract.declaror
    this.dummy = global.bridge.seat.south.partner.name
    this.turn = 'south'

    this.game = game
    this.phase = 'play'
    this.singleSuitDisplayed = false
  }

  // test = function () {
  //   var game = new global.bridge.Game()
  //   game.contract.level = 3
  //   game.contract.denomination = 'NT'
  //   game.contract.declaror = global.bridge.seat.east

  //   var board = new global.bridge.Board()
  //   board.setNumber(1) // Do we need this ? What does it do ?
  //   board.deal(this.scenario[0])

  //   var x = game.play('TS')
  //   var z = game.play()
  //   console.log(x.name + ' played ' + z[0].rank + z[0].suit)
  //   x = game.play('6C')
  //   z = game.play()
  //   console.log(x.name + ' played ' + z[1].rank + z[1].suit)
  //   x = game.play('8S')
  //   z = game.play()
  //   console.log(x.name + ' played ' + z[2].rank + z[2].suit)
  //   x = game.play('2C')
  //   z = game.play()
  //   console.log(x.name + ' played ' + z[3].rank + z[3].suit)
  //   console.log('Winning player is ' + x.next)
  //   this.game = game
  // }

  botPlay = function (player) {
    // NOTE: Need to add bind otherwise this does not point to GameStore
    setTimeout(function () {
      // var randomCardIndex = Math.floor(Math.random() * this.hands[player].length - 2) + 1

      // var cardId = player + randomCardIndex

      // document.getElementById(cardId).click()

      // Get cards of the suit led
      var cardsOfLeadSuit = this.getSingleSuitCards(player, this.leadCardSuit)

      // Remove the dot separators from the pbn format
      cardsOfLeadSuit = cardsOfLeadSuit.split('.').join('')

      if (cardsOfLeadSuit.length) {
        this.singleSuitHands[player] = cardsOfLeadSuit
        // Yes we have some
        // Play the first one
        this.playCard(player, cardsOfLeadSuit[0], this.leadCardSuit)
      } else {

      }
      // console.log('Bots Turn ' + player + ' ' + cardId)
    }.bind(this),
    3000)
  }

  //
  // FUNCTION: validateCard
  //
  // Check that a valid card has been played
  // If a player has a card of the suit that
  // led this trick then it must be played
  //
  // INPUT: player - north, south, east or west
  //        suit   - S, H, D, C
  //
  validateCard = function (player, suit) {
    // Is this the same suit as the lead suit ?
    // ok to play
    if (suit === this.leadCardSuit) {
      return true
    }

    // Do we have any cards of the lead suit ?
    if (this.board.hands[player].cardsWithSuit(this.leadCardSuit).length > 0) {
      return false
    } else {
      return true
    }
  }

  //
  // FUNCTION: playCard
  //
  // Moves card on to the table
  // Removes card from player's hand
  // Plays card in the Game
  // Sets up next player's turn
  // Determines winner of trick if necessary
  //
  // INPUT: player - north, south, east or west
  //        rank   - card value
  //        suit   - S, H, D, C
  //
  // RETURN: Null or error message

  playCard = function (player = 'south', rank, suit) {
    // If it's not this player's turn do nothing
    if (player !== this.turn) {
      return null
    }

    // Set lead card suit
    if (this.turnNumber === 1) {
      this.leadCardSuit = suit
    }

    // Check a valid card has been played
    var validCard = this.validateCard(player, suit)
    if (!validCard) {
      return rank + suit + ' is not allowed to be played'
    }

    // if (!this.singleSuitDisplayed) {
    if (this.singleSuitHands[player].length === 0) {
      // We've clicked on our hand so
      // just display cards of that suit
      this.singleSuitHands[player] = this.getSingleSuitCards(player, suit)
      return
    }

    // Revert the hand to display all cards
    this.singleSuitHands[player] = ''

    // Move card to table view
    this.table[player].suit = suit
    this.table[player].rank = rank

    // Remove card from hand
    this.removeCardFromHand(player, rank, suit)

    // Play card in Game
    var next = this.game.play(rank + suit)

    // Set next person to play
    this.turn = next.name

    // If we've had four cards played then determine winner
    if (++this.turnNumber > 4) {
      this.winner = this.turn

      this.turn = null
      this.tricksWon.push(this.winner)
      if (this.winner === 'north' || this.winner === 'south') {
        this.tricksWonByPartners.northsouth++
      } else {
        this.tricksWonByPartners.eastwest++
      }
      this.turnNumber = 1
      this.trickNumber++

      // Set timeout before clearing the Table
      // and checking to see if game is over
      setTimeout(() => {
        this.table = {
          north: {
            rank: '',
            suit: ''
          },
          east: {
            rank: '',
            suit: ''
          },
          south: {
            rank: '',
            suit: ''
          },
          west: {
            rank: '',
            suit: ''
          }
        }

        this.turn = next.name

        if (this.tricksWon.length === 13) {
          this.gameOver = true
        }
      },
      3000)
    }
  }

  //
  // FUNCTION: removeCardFromHand
  //
  // Removes given card from given player's hand
  // in this.hands
  //
  // INPUT: player     - north, south, east or west
  //        searchRank - card value
  //        searchSuit - S, H, D, C
  //

  removeCardFromHand = function (player, searchRank, searchSuit) {
    var hand = this.hands[player]

    var startSearchFrom = this.getPosition(hand, '.', this.suitOrder[searchSuit])

    var cardIndexToRemove = hand.indexOf(searchRank, startSearchFrom)
    var beforeSearch = hand.substring(0, cardIndexToRemove)
    var afterSearch = hand.substring(cardIndexToRemove + 1, hand.length)

    hand = beforeSearch + afterSearch
    this.hands[player] = hand

    this.removeCardFromBoardHand(player, searchRank, searchSuit)
  }

  //
  // FUNCTION: getPosition
  //
  // Returns n'th occurence of character m in string str
  //

  getPosition = function (str, m, n) {
    return str.split(m, n).join(m).length
  }

  //
  // FUNCTION: removeCardFromBoardHand
  //
  // Removes given card from given player's hand
  // in this.board.hands.south.cards
  // bridge.js doesn't do this so we will have to
  //
  // INPUT: player     - north, south, east or west
  //        searchRank - card value
  //        searchSuit - S, H, D, C
  //

  removeCardFromBoardHand = function (player, searchRank, searchSuit) {
    var hand = this.board.hands[player].cards

    var index
    for (let i = 0; i < hand.length; i++) {
      if (hand[i].rank === searchRank && hand[i].suit === searchSuit) {
        index = i
        break
      }
    }

    this.board.hands[player].cards.splice(index, 1)
  }

  //
  // FUNCTION: getSingleSuitCards
  //
  // Builds a PBN hand of the selected suit
  // from the player's hand
  //
  // INPUT: player     - north, south, east or west
  //        searchSuit - S, H, D, C
  //

  getSingleSuitCards = function (player, searchSuit) {
    // BridgeJS function to do this:
    // this.board.hands['south'].cardsWithSuit('D')
    // but then how do we put it in PBN to display ?

    // var hand = this.board.hands[player].cards

    // var filteredHand = hand.filter(function (card) {
    //   return card.suit === searchSuit
    // })

    // return filteredHand

    var firstCardOfSuit
    var firstCardOfNextSuit
    var searchFrom
    var hand = this.hands[player]

    firstCardOfSuit = this.getPosition(hand, '.', this.suitOrder[searchSuit])

    if (searchSuit === 'C') {
      firstCardOfNextSuit = hand.length
    } else {
      firstCardOfNextSuit = this.getPosition(hand, '.', this.suitOrder[searchSuit] + 1)
    }

    if (searchSuit === 'S') {
      searchFrom = firstCardOfSuit
    } else {
      searchFrom = firstCardOfSuit + 1
    }
    var singleHand = hand.substring(searchFrom, firstCardOfNextSuit)

    // Now add the appropriate number of dots
    // to the start so we get the PBN in the right format
    var dot = '.'
    return dot.repeat(this.suitOrder[searchSuit]) + singleHand
  }

  //
  // FUNCTION: getTrickHistory
  //
  // Returns an HTML table of the tricks played
  //

  getTrickHistory = function () {
    if (!this.game || !this.game.tricks) {
      return 'No tricks played yet'
    } else {
      return this.game.tricks
    }
  }

  //
  // FUNCTION: getBid
  //
  // Returns the player's bid
  //
  // INPUT: player - N, E, S, W
  //        bidRound - integer

  getBid = function (player, bidRound) {
    if (this.bids.length < bidRound) {
      return null
    }

    var bid = this.bids[bidRound - 1]

    return bid[player]
  }
}

export default new GameStore()
