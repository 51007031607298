// dependencies
import React from 'react'
import { Text } from 'rebass'

// components
import Translate from 'App/components/text/Translate.js'

export default function (props) {
  return (
    <Text {...props}>
      <Translate>
        {props.children}
      </Translate>
    </Text>
  )
}
