import React from 'react'

import Text from 'App/components/text/Text.js'
import Translate from 'App/components/text/Translate.js'

export default function (props) {
  return (
    <li>
      <Text {...props}>
        <Translate>{props.children}</Translate>
      </Text>
    </li>
  )
}
