//
// Component: MessageModal
//
// Displays a full screen modal
//
// To Use:
//
// Add these lines to the constructor of the component calling the modal
//
// this.showModal = this.showModal.bind(this)
// this.closeModal = this.closeModal.bind(this)
//
// Add a state
//
// modalIsOpen: false
//
// And add these functions
//
// showModal () {
// this.setState({
//   modalIsOpen: true
// })
// }

// closeModal () {
// this.setState({
//   modalIsOpen: false
// })
// }
//
// And call as follows:
//
// <MessageModal showModal={this.state.modalIsOpen} closeModal={this.closeModal}>
//   <Text>Message goes here</Text>
// </MessageModal>
//
// NOTE: Also pass closeWithOverlay to allow closing modal
//       by clicking on overlay otherwise will need a button to close

import React from 'react'
import Modal from 'react-modal'

class MessageModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
    }
  }

  render () {
    return (
      <Modal
        isOpen={this.props.showModal}
        ariaHideApp={false} // See http://reactcommunity.org/react-modal/accessibility/
        // eslint-disable-next-line react/jsx-handler-names
        onRequestClose={this.props.closeWithOverlay ? this.props.closeModal : undefined}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
          },
          content: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 16,
            borderTop: 4,
            borderRight: 4,
            borderBottom: 4,
            borderLeft: 4,
            borderColor: '#492a2c',
            borderStyle: 'solid',
            backgroundColor: '#fff',
            color: '#000',
            p: 3,
            boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.75)',
            width: 350,
            height: 350,
            fontSize: 24,
            fontWeight: 700
          }
        }}
      >
        {this.props.children}
      </Modal>
    )
  }
}
export default MessageModal
