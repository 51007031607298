//
// Component: PlayerBid
//
// Displays the bid made by the
// other three players
//

// dependencies
import React from 'react'
import { Box, Button, Flex } from 'rebass'

// components
import BidCard from 'App/components/auction/BidCard.js'
// import H1 from 'App/components/text/H1.js'
// import Text from 'App/components/text/Text.js'

// stores
// import GameStore from 'App/stores/GameStore'

class MyBid extends React.Component {
  //
  // props
  //
  // player - north, south, east, west
  // bidValue - 1-7
  // bidSuit - S, H, D, C
  // pass - true - omit bidValue and bidSuit if true
  // comment - text to display
  //

  constructor (props) {
    super(props)
    this.state = {
      bid: {
        bid1: false,
        bid2: false,
        bid3: false,
        bid4: false
      },
      bidReason: '',
      correctOptionSelected: false
    }
    this.doNextBid = this.doNextBid.bind(this)
  }

  componentDidMount () {

  }

  doNextBid () {
    this.props.doNextBid()
  }

  selectBid (bid) {
    var bids = {
      bid1: bid === 1 ? !this.state.bid.bid1 : false,
      bid2: bid === 2 ? !this.state.bid.bid2 : false,
      bid3: bid === 3 ? !this.state.bid.bid3 : false,
      bid4: bid === 4 ? !this.state.bid.bid4 : false
    }

    var bidReason

    switch (bid) {
      case 1:
        bidReason = this.props.bid[0].reason
        break
      case 2:
        bidReason = this.props.bid[1].reason
        break
      case 3:
        bidReason = this.props.bid[2].reason
        break
      case 4:
        bidReason = this.props.bid[3].reason
        break
    }

    this.setState({
      bid: bids,
      bidReason: bidReason,
      correctOptionSelected: bid === this.props.validOption
    })
  }

  render () {
    var suitName = {
      S: 'spade',
      H: 'heart',
      D: 'diamond',
      C: 'club'
    }

    // const x = <BidCard bidValue={this.props.bid[0].value} bidSuit={suitName[this.props.bid[0].suit]} pass={this.props.bid[0].pass} alignSelf='center' />

    return (
      <Flex
        sx={{
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            textTransform: 'uppercase',
            margin: '0 auto',
            mb: [20]
          }}
        >
          Your Bid
        </Box>
        <Flex
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: [250]
          }}
        >
          <BidCard className={`bid ${this.state.bid.bid1 ? 'selected' : null}`} bidValue={this.props.bid[0].value} bidSuit={suitName[this.props.bid[0].suit]} pass={this.props.bid[0].pass} alignSelf='center' onClick={() => { this.selectBid(1) }} />
          <BidCard className={`bid ${this.state.bid.bid2 ? 'selected' : null}`} bidValue={this.props.bid[1].value} bidSuit={suitName[this.props.bid[1].suit]} pass={this.props.bid[1].pass} alignSelf='center' onClick={() => { this.selectBid(2) }} />
          <BidCard className={`bid ${this.state.bid.bid3 ? 'selected' : null}`} bidValue={this.props.bid[2].value} bidSuit={suitName[this.props.bid[2].suit]} pass={this.props.bid[2].pass} alignSelf='center' onClick={() => { this.selectBid(3) }} />
          <BidCard className={`bid ${this.state.bid.bid4 ? 'selected' : null}`} bidValue={this.props.bid[3].value} bidSuit={suitName[this.props.bid[3].suit]} pass={this.props.bid[3].pass} alignSelf='center' onClick={() => { this.selectBid(4) }} />
        </Flex>
        <Box>
          {this.state.bidReason}
        </Box>
        <Button
          sx={{
            textTransform: 'uppercase',
            backgroundColor: '#46282C',
            borderRadius: 0
          }}
          // eslint-disable-next-line react/jsx-handler-names
          onClick={this.doNextBid}
          disabled={!this.state.correctOptionSelected}
        >
            Continue
        </Button>
      </Flex>
    )
  }
}
export default MyBid
